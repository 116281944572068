const stepsWorkbook = [
  {
    selector: '.start-workbook-tour',
    content: 'Enter Workbook Details',
  },
  {
    selector: '.first-workbook-step',
    content: 'Enter your Workbook name',
  },
  {
    selector: '.second-workbook-step',
    content: 'Enter your Workbook description',
  },
  {
    selector: '.third-workbook-step',
    content: 'click to save workbook',
  },
  // ...
];

export default stepsWorkbook;
